<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Paciente*</label>
      <SelectClient ref="elSelectClient" v-model="client_id"></SelectClient>
      <app-small-form-errors :errors="errors.client_id"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Tipo de Archivo*</label>
      <SelectType v-model="type_id"></SelectType>
      <app-small-form-errors :errors="errors.type_id"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Estado*</label>
      <SelectState v-model="state_id"></SelectState>
      <app-small-form-errors :errors="errors.state_id"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Codigo*</label>
      <input
        class="form-control"
        type="text"
        maxlength="255"
        v-model="code"
      />
      <app-small-form-errors :errors="errors.code"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for="">Archivo</label>
      <div class="custom-file">
        <a class="d-block" :href="file_url" v-if="file_url">
          <img :src="file_url" alt="Archivo" width="100%">
        </a>
        <form ref="elSubForm">
          <input
            type="file"
            ref="elInputFile"
            id="customFile"
          />
        </form>
      </div>
    </div>
    <app-button-submit :disabled="errors"></app-button-submit>
  </form>
</template>

<script>
import SelectClient from "../../store-module/clients/Select";
import SelectType from "./SelectType";
import SelectState from "./SelectState.vue"
import * as validate from "validate.js";
import { DentalClinicService } from "../service";

const formRules = {
  client_id: { presence: { message: "Requerido" } },
  type_id: { presence: { message: "Requerido" } },
  code: { presence: { message: "Requerido" } },
};

export default {
  components: {
    SelectClient,
    SelectType,
    SelectState,
  },
  data: () => ({
    id: null,
    client_id: null,
    type_id: null,
    state_id: null,
    code: null,
    file: null,
    file_url: null,
  }),
  computed: {
    errors() {
      let err = validate(this.$data, formRules, { fullMessages: false });
      return err ? err : false;
    },
  },
  methods: {
    reset() {
      this.id = null;
      this.client_id = null;
      this.code = null;
      this.type_id = null;
      this.state_id = null;
      this.file = null;
      this.file_url = null;
      this.$refs.elSelectClient.reset();
      this.$refs.elSubForm.reset();
    },
    setValue(reg) {
      this.$refs.elSelectClient.setValueFromId(reg.client_id);
      this.$refs.elSubForm.reset();
      this.id = reg.id || null;
      this.client_id = reg.client_id;
      this.code = reg.code;
      this.type_id = reg.type_id;
      this.state_id = reg.state_id;
      if(reg.file_path) {
        this.file_url = process.env.VUE_APP_API_URL + "storage/" + reg.file_path;
      } else {
        this.file_url = null;
      }
    },
    save() {
      if(this.$refs.elInputFile.files.length > 0) {
        this.file = this.$refs.elInputFile.files[0];
      }
      DentalClinicService.saveClientFile(this.$data).then(() =>
        this.$emit("submitted")
      );
    }
  }
};
</script>

<style>
</style>
