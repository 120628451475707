<template>
  <div>
    <h4>ARCHIVOS DE PACIENTES</h4>
    <app-table-registers
      ref="tr"
      :getList="getList"
      @btnNew="
        $refs.dfcf.show();
        $refs.fcf.reset();
      "
    >
      <template slot="table">
        <thead>
          <tr>
            <!-- <th>ID</th> -->
            <th>NOMBRE DEL PACIENTE</th>
            <th class="text-center">CODIGO</th>
            <th>TIPO</th>
            <th>ESTADO</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list.data" :key="l.id">
            <!-- <td>{{ l.id }} </td> -->
            <td>
              <router-link :to="'/dental-clinic/patients/' + l.client_id + '/files'"
                >{{ l.client_name }}</router-link>
            </td>
            <td class="text-center">
              <b>{{ l.code }}</b>
            </td>
            <td>{{ l.type }}</td>
            <td>
              <SelectState
                v-model="l.state_id"
                @change="changeStatus(l)"
                class="form-control-sm"
              ></SelectState>
            </td>
            <td>
              <button class="btn btn-light btn-sm" @click="
                $refs.dfcf.show();
                $refs.fcf.setValue(l);">
                <i class="fa fa-edit"></i>
              </button>
              <button
                @click="
                  $refs.dcfpd.val = l.id;
                  $refs.dcfpd.show();
                "
                class="btn btn-light btn-sm"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="dfcf">
      <Form
        ref="fcf"
        @submitted="
          $refs.tr.gl();
          $refs.dfcf.hide();
        "
      ></Form>
    </app-modal-basic>

    <app-modal-yn
      ref="dcfpd"
      @yes="deleteItem($refs.dcfpd.val)"
      @no="$refs.dcfpd.hide()"
    >
      <p v-if="$refs.dcfpd && $refs.dcfpd.val">
        Seguro de eliminar el registro <b>{{ $refs.dcfpd.val }}</b
        >?
      </p>
    </app-modal-yn>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import SelectState from "./SelectState";
import Form from "./Form";

export default {
  components: {
    SelectState,
    Form
  },
  data: () => ({
    list: {}
  }),
  methods: {
    changeStatus(item) {
      DentalClinicService.saveClientFileStatus({
        id: item.id,
        state_id: item.state_id,
        employe_id: this.idEmploy
      });
    },
    deleteItem(id) {
      DentalClinicService.deleteClientFile(id).then(() => {
        this.$refs.dcfpd.hide();
        this.$refs.tr.gl();
      });
    },
    getList({ page, search }) {
      return new Promise(rsv => {
        DentalClinicService.getPatientFiles({
          page,
          search
        }).then(res => {
          (this.list = res), rsv(res);
        });
      });
    }
  }
};
</script>

<style>
</style>
