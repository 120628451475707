<template>
  <select class="form-control" v-model="valueChild" @change="$emit('change', $event)">
    <option v-for="(l,index) in listStates" :key="index" :value="index">{{l}} </option>
  </select>
</template>

<script>
export default {
  props: {
    value: {}
  },
  computed: {
    listStates() {
      return this.$store.state.config.clinic ? this.$store.state.config.clinic.client_files.types : [];
    },
    valueChild: {
      get () { return this.value; },
      set(val) { this.$emit('input', val) }
    }
  }
}
</script>

<style>

</style>
